import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/account": [~4],
		"/bookmarks": [~5],
		"/chat": [~6],
		"/chat/[id]": [~7],
		"/custom-tools": [8],
		"/custom-tools/[id]": [~9],
		"/icons": [10],
		"/login": [~11],
		"/login/error": [12],
		"/login/popup": [13],
		"/logout": [14],
		"/materials": [~15],
		"/playground": [16],
		"/privacy-policy": [17],
		"/published": [~18],
		"/search": [~19],
		"/sessions": [~22],
		"/session": [~20],
		"/session/[id]": [~21],
		"/settings": [~23],
		"/share/[id]": [~24,[],[2]],
		"/share/[id]/[artifactId]": [~25,[],[2]],
		"/terms-of-service": [26],
		"/tools": [27],
		"/upload": [~28]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';